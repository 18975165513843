import { NgClass, NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { toastError } from '@presentation/shared/toast';
import { BaseComponent } from 'app/presentation/base/base.component';
import { KYCPresenter } from './presentation/kyc-form.presenter';
import { KYCSideEffect } from './presentation/kyc-form.side-effects';
import { KYCViewEvent } from './presentation/kyc-form.view-events';
import { KYCViewState } from './presentation/kyc-form.view-state';

@Component({
  selector: 'app-kyc-form',
  templateUrl: './kyc-form.component.html',
  styleUrls: ['./kyc-form.component.scss'],
  standalone: true,
  imports: [MatRadioModule, NgClass, NgIf, FormsModule, ReactiveFormsModule],
  providers: [KYCPresenter],
})
export class KycFormComponent extends BaseComponent<
  KYCPresenter,
  KYCViewState,
  KYCViewEvent,
  KYCSideEffect
> {
  public presenter: KYCPresenter = inject(KYCPresenter);

  public KYCForm: UntypedFormGroup = new UntypedFormGroup({
    idNumber: new FormControl('', [Validators.required]),
  });

  emitUpload($event: any, inputElement: HTMLInputElement): void {
    const file = $event.target.files[0];

    // make sure the uploaded file does not exceed 5MB
    const maxFileSize = 5 * 1024 * 1024;
    if (file.size > maxFileSize) {
      toastError(
        this.trans('validation.maxFileSize', {
          size: '5MB',
        }),
      );
      this.presenter.emitViewEvent({ type: 'RemoveUploadedFile' });
    } else {
      this.presenter.emitViewEvent({ type: 'UploadFile', event: $event });
    }

    // Clear the file input value
    inputElement.value = '';
  }

  emitClear(): void {
    this.presenter.emitViewEvent({ type: 'RemoveUploadedFile' });
  }

  onSubmit(): void {
    this.presenter.emitViewEvent({ type: 'Submit', data: this.KYCForm.value });
  }
}
