import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideRouter } from '@angular/router';
import { KycFormComponent } from './kyc-form/kyc-form.component';

@NgModule({
  exports: [],
  imports: [CommonModule],
  providers: [
    provideRouter([
      {
        path: '',
        pathMatch: 'full',
        component: KycFormComponent,
      },
      {
        path: '**',
        redirectTo: '',
      },
    ]),
  ],
  declarations: [],
})
export class KYCModule {}
