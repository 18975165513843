<div class="top-shadow"></div>
<p class="body1--bold title content-main">{{ trans('kyc.header') }}</p>

<form [formGroup]="KYCForm" class="form-container">
  <!-- header section -->
  <section class="header">
    <img src="/assets/img/id-card.svg" alt="" />
    <div>
      <p class="body2--bold content-main">{{ trans('kyc.title') }}</p>
      <p class="caption1--medium">{{ trans('kyc.disclaimer') }}</p>
    </div>
  </section>

  <!-- upload image section -->
  <section class="id-form__upload">
    <p class="id-form__upload--text">
      {{ trans('kyc.uploadTitle') }}
    </p>
    <div class="avatar-upload">
      <div class="avatar-upload">
        <div class="avatar-edit">
          <input
            type="file"
            id="imageUpload2"
            accept=".png, .jpg, .jpeg"
            #fileInput
            (input)="emitUpload($event, fileInput)"
          />
        </div>
        <div class="avatar">
          <div
            class="avatar-preview"
            (click)="fileInput.click()"
            [ngClass]="!viewState.shouldShowInitialImage ? '' : 'placeholder'"
          >
            <div class="avatar-preview-upload">
              <div
                ngClass="imagePreview"
                [style.backgroundImage]="'url(' + viewState.imageUrl + ')'"
              ></div>
              <p class="body2--regular" *ngIf="viewState.shouldShowInitialImage">
                {{ trans('kyc.uploadText') }}
              </p>
            </div>
          </div>
          <img
            *ngIf="!viewState.shouldShowInitialImage"
            src="assets/img/customer-effort-support/cancel-filling-questionnaire.svg"
            class="delete-icon"
            (click)="emitClear()"
          />
        </div>
      </div>
    </div>
  </section>

  <!-- enter national id section -->
  <section>
    <p>{{ trans('kyc.idTitle') }}</p>
    <input class="id-input" formControlName="idNumber" placeholder="{{ trans('kyc.id') }}" />
  </section>

  <!--  submit -->
  <button
    class="submit-btn body1--bold"
    [ngClass]="{ disabled: !viewState.fileSelected || KYCForm.invalid }"
    (click)="onSubmit()"
    [disabled]="!viewState.fileSelected || KYCForm.invalid"
  >
    {{ trans('kyc.submit') }}
  </button>
</form>

<!-- verification time disclaimer -->
<div class="body2--bold information-text">
  <img src="assets/img/info-circle.svg" />
  {{ trans('kyc.warning') }}
</div>
